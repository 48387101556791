"use strict";

let navButtons = document.querySelectorAll(".js-nav-btn");
let menuBtn = document.getElementById("js-menu-btn");
let body = document.querySelector("body");
let header = document.querySelector(".header");
let logo = document.querySelector(".js-logo");

menuBtn.addEventListener("click", () => {
  if (body.classList.contains("isMobMenuOpen")) {
    handleMenuClose();
  } else {
    handleMenuOpen();
  }
});

logo.addEventListener("click", () => {
  handleNavClick();
});

for (let button of navButtons) {
  button.addEventListener("click", (e) => {
    if (body.classList.contains("isMobMenuOpen")) {
      e.preventDefault();
      let element = document.getElementById(e.target.hash.slice(1));
      handleNavClick(element);
      handleMenuClose();
    }
  });
}

function handleNavClick(element) {
  if (element) {
    window.scrollTo({
      top: element.offsetTop - 64,
      left: 0,
      behavior: "smooth",
    });
  }
}

function handleMenuOpen() {
  header.classList.add("isMobMenuOpen");
  body.classList.add("isMobMenuOpen");
}

function handleMenuClose() {
  header.classList.remove("isMobMenuOpen");
  body.classList.remove("isMobMenuOpen");
}

// Get the modal
var modal = document.getElementById("modal");

// Get the button that opens the modal
var btn = document.getElementById("modalBtn");

// Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

// When the user clicks on the button, open the modal
btn.onclick = function () {
  modal.style.display = "block";
};

// When the user clicks on <span> (x), close the modal
span.onclick = function () {
  modal.style.display = "none";
};

// When the user clicks anywhere outside of the modal, close it
window.onclick = function (event) {
  if (event.target == modal) {
    modal.style.display = "none";
  }
};
